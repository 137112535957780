var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticStyle:{"table-layout":"fixed"},attrs:{"width":"100%"}},[_vm._l((_vm.files),function(file,index){return [_c('tr',{key:`file-row-${index}`,class:{
          'table-alternate-row': index % 2,
          'd-flex  flex-column': !_vm.allowAddMore && _vm.directionColumn,
        }},[_c('td',{class:[!_vm.allowAddMore && _vm.directionColumn ? 'd-flex  flex-column' : 'pr-2'],staticStyle:{"vertical-align":"baseline"}},[_c('v-file-input',{class:[{ required: _vm.required && !file.file }, ..._vm.customClassArr],attrs:{"id":`document-file-${index}`,"placeholder":"Select File","outlined":"","accept":_vm.accept,"prepend-icon":"","prepend-inner-icon":"mdi-attachment","hide-details":"","show-size":_vm.showSize,"rules":_vm.rules(file.file, `File ${index + 1}`, true)},on:{"change":function($event){return _vm.updateFile(index, $event)},"click:clear":function($event){_vm.updateFile(index, $event), _vm.$emit('clearFile')}},model:{value:(file.file),callback:function ($$v) {_vm.$set(file, "file", $$v)},expression:"file.file"}})],1),_c('td',{class:[
            !_vm.allowAddMore && _vm.directionColumn
              ? 'd-flex  flex-column'
              : 'px-2' && _vm.alignColumn
              ? 'pt-2'
              : 'px-2',
          ]},[_c('TextInput',{attrs:{"id":`document-name-${index}`,"counter":"50","rules":[(v) => (v ? v.length <= 50 : true) || `File Name maximum 50 characters`],"hideTopMargin":"","placeholder":"File Name","customClass":"mt-0","suffix":file.suffix},model:{value:(file.name),callback:function ($$v) {_vm.$set(file, "name", $$v)},expression:"file.name"}})],1),(_vm.allowAddMore)?_c('td',{staticClass:"pl-2",attrs:{"width":"10%","align":"center","valign":"top"}},[_c('v-btn',{class:_vm.customClassArr,attrs:{"color":"red lighten-1 white--text","icon":""},on:{"click":function($event){return _vm.removeFile(index)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1):_vm._e()])]}),(_vm.allowAddMore)?_c('tr',[_c('td',{staticClass:"pr-2"}),_c('td',{staticClass:"pr-2"},[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{class:_vm.customClassArr,attrs:{"color":"blue darken-4 white--text","tile":"","depressed":""},on:{"click":function($event){return _vm.addMore()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add More... ")],1)],1)]),_c('td',{staticClass:"pr-2"})]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }